// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Gotham-Medium.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-weight: medium;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Gotham-Medium", sans-serif;
  background-color: #E5E5E5;
  height: 100%;
  overflow-x: hidden;
}

.swal-button {
  background-color: #5C2682 !important;
  color: #FFB81C !important;
}

label {
  height: 24px;
  left: 46px;
  top: 222px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  letter-spacing: -0.011em;
  color: #2F0248;
  padding-bottom: 30px;
  padding-top: 30px;
}

.registration-link {
  height: 13px;
  left: 142.5px;
  top: 500.5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: linear-gradient(89.92deg, #60088C 0.07%, #A11E90 92.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.cursor-pointer:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,mFAAA;EACA,mBAAA;AACF;AAEA;EACE,sBAAA;EACA,SAAA;EACA,UAAA;AAAF;;AAGA;;EAEE,wCAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,yBAAA;AAAF;;AAGA;EACE,YAAA;EACA,UAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,wBAAA;EACA,cAAA;EACA,oBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oEAAA;EACA,6BAAA;EACA,oCAAA;EACA,qBAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":["@font-face {\n  font-family: \"Gotham-Medium\";\n  src: local(\"Gotham-Medium\"), url(\"./assets/fonts/Gotham-Medium.woff\") format(\"woff\");\n  font-weight: medium;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\nhtml,\nbody {\n  font-family: \"Gotham-Medium\", sans-serif;\n  background-color: #E5E5E5;\n  height: 100%;\n  overflow-x: hidden;\n}\n\n.swal-button {\n  background-color: #5C2682 !important;\n  color: #FFB81C !important\n}\n\nlabel {\n  height: 24px;\n  left: 46px;\n  top: 222px;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 24px;\n  display: flex;\n  letter-spacing: -0.011em;\n  color: #2F0248;\n  padding-bottom: 30px;\n  padding-top: 30px;\n}\n\n.registration-link {\n  height: 13px;\n  left: 142.5px;\n  top: 500.5px;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 13px;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  background: linear-gradient(89.92deg, #60088C 0.07%, #A11E90 92.22%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  background-clip: text;\n}\n\n.cursor-pointer:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
