import { config } from '../config';
import CryptoJS from 'crypto-js';

export const generateHeader = () => {
  const dateToUse = new Date();
  const UTCTimestamps = dateToUse.toISOString().replace('Z', '');
  const dateInToken = UTCTimestamps.replace('T', '')
    .replace(':', '')
    .replace(':', '')
    .substring(0, UTCTimestamps.length - 7);
  const shaOneEncrypt = CryptoJS.SHA512(dateInToken + config.client_id + config.xtoken_password);
  const apiHeader = {
    'x-token': shaOneEncrypt.toString(CryptoJS.enc.Hex),
    Client_ID: config.client_id,
    'Ocp-Apim-Subscription-Key': config.subscription_key_value,
    UTCTimestamp: UTCTimestamps,
  };
  return apiHeader;
};
